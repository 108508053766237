a_token = "";
uuid = null;

$("document").ready(function(){
	//syncAuth();
	//setInterval(function(){ syncAuth(); }, 3000);
	
	$(".btn-logout").bind('click touchstart', function(){if (!actionFlag) {actionFlagUp();
		console.log("Logout Started");
		openLogoutModal();
		}
	});
	
  	$(".btn-auth-login").click(function(){
	  	openAuthModal();
  	});	
	
});

$(window).focus(function(){
	//syncAuth();
});

function syncAuth(){
	var token = $.getMeta('property','token');
	var transData = {
		'_token' : token
	}
	
	$.ajax({
	  type:'POST',		
	  url: '/auth/syncToken',
	  data : transData,
	  dataType: "json",
	  success: function(data){
		  console.log(data);
		  if(data.code == 100){
			var lmsUrl = $.getMeta('property','lms');
			a_token = data.a_token;
			var authUrl = lmsUrl + "/local/ubauth/api/v1.php" + "?atk=" + a_token;
		
			$.ajax({
			  url: authUrl,
			  dataType: "script",
			  success: function(){
				console.log(ubAuth.code);
			  	authPass(ubAuth.code);
			  }
			});			  

			  
		  }
	  }
	});
}

function getUrlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function(m,key,value) {
        vars[key] = value;
    });
    return vars;
}

function popupCenter(url, title, w, h, opts) {
   var _innerOpts = '';
   if(opts !== null && typeof opts === 'object' ){
       for (var p in opts ) {
           if (opts.hasOwnProperty(p)) {
               _innerOpts += p + '=' + opts[p] + ',';
           }
       }
   }
     // Fixes dual-screen position, Most browsers, Firefox
   var dualScreenLeft = window.screenLeft != undefined ? window.screenLeft : screen.left;
   var dualScreenTop = window.screenTop != undefined ? window.screenTop : screen.top;

   var width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : screen.width;
   var height = window.innerHeight ? window.innerHeight : document.documentElement.clientHeight ? document.documentElement.clientHeight : screen.height;

   var left = ((width / 2) - (w / 2)) + dualScreenLeft;
   var top = ((height / 2) - (h / 2)) + dualScreenTop;
   var popupWindow = window.open(url, title, _innerOpts + ' width=' + w + ', height=' + h + ', top=' + top + ', left=' + left);
   return popupWindow;
// Puts focus on the newWindow
/*
   if (window.focus) {
       newWindow.focus();
   }
*/
}

function popupPolling() {
    if(popupChildWindow && popupChildWindow.closed) {	        
        // The popup has been closed, stop the timer and reload window.
        clearInterval(popupTimer);
        var redirect = getUrlVars()['redirect'];			
			redirect = decodeURIComponent(redirect);			
			redirect = redirect.replace("#","");
			
			console.log(redirect);
		if (redirect != 'undefined'){
			window.parent.location.href = redirect;
		}else{
			location.reload();
		}
    }
}

function authPass(code){

	var token = $.getMeta('property','token');

	var transData = {
		'_token' : token,
		'code' : code,		
		'a_token' : a_token
	}
	
	$.ajax({
	  type:'POST',		
	  url: '/auth/remoteAuth',
	  data : transData,
	  dataType: "json",
	  success: function(data){
		  console.log(data);
		  window.location.href = '/';
		  //viewSync();
		  /*
		  switch(data.code){
			  case 100:
			  	viewSync();
			  break;
			  default:
				  viewSync();
			  break;
		  }
		  */
	  	//authPass(ubAuth.code);
	  }
	});
		
}

function setUserInfo(){
	var token = $.getMeta('property','token');	

	var transData = {
		'_token' : token
	}
	
	$.ajax({
	  type:'POST',
	  url: '/auth/userInfo',
	  data : transData,
	  dataType: "json",
	  success: function(data){
		  switch(data.code){ 
			  case 100:
			  	uuid = data.uuid;  	
			  break;
			  default:
			  	uuid = null;			  	
			  break;
		  }
	  }
	});	
	
}

function viewSync(){
	//console.log("test");
	var token = $.getMeta('property','token');	

	var transData = {
		'_token' : token
	}
	
	$.ajax({
	  type:'POST',
	  url: '/auth/userInfo',
	  data : transData,
	  dataType: "json",
	  success: function(data){

		  switch(data.code){ 
			  case 100:
			  	uuid = data.uuid;
			  	var lmsUrl = $.getMeta('property','lms');
			  	$(".btn-show-login").html("");

				var userBoxHtml = '<div class="me dropdown-toggle" id="user-dropdown" data-toggle="dropdown" aria-hashpopup="true" aria-expanded="false">'
				userBoxHtml += '<span>';
					userBoxHtml += '<a href="' + lmsUrl + '" target="_blank"><img class="img-circle" src="' + lmsUrl + '/user/pix.php/' + data.uuid + '/f1.jpg" style="width: 30px;"></a>';
				userBoxHtml += '</span>';
				//userBoxHtml += '<span style="font-size:19px;"> ' + data.username + '</span>';
				userBoxHtml += '</div>';
				
				var userBoxMenu = '<ul class="dropdown-menu pull-right" style="">';
				/*
				userBoxMenu += '<li>';						
					userBoxMenu += '<a href="/myClass/alerts"><i class="fa fa-bell-o" aria-hidden="true"></i> 알림 ';
					userBoxMenu += '<span class="circle red">1</span>';
					userBoxMenu += '</a>';										
				userBoxMenu += '</li>';
				*/
				userBoxMenu += '<li class="divider" role="seprator"></li>';				
				userBoxMenu += '<li>';	
					userBoxMenu += '<a href="/my">내 페이지</a>';				
					userBoxMenu += '<a href="/myClass/courses">내 강좌</a>';
				userBoxMenu += '</li>';
				userBoxMenu += '<li class="divider" role="seprator"></li>';
				userBoxMenu += '<li>';				
					userBoxMenu += '<a href="/myClass/settings"><i class="fa fa-cogs" aria-hidden="true"></i> 설정</a>';
				userBoxMenu += '</li>';					
				userBoxMenu += '<li class="divider" role="seprator"></li>';
				userBoxMenu += '<li>';
					userBoxMenu += '<a class="btn-logout" href="#"><i class="fa fa-sign-out" aria-hidden="true"></i><span> Logout</span></a>';
				userBoxMenu += '</li>';
				userBoxMenu += '</ul>';
				
				
				var wideBtn = userBoxHtml + userBoxMenu;
				wideBtn = $.parseHTML(wideBtn);
				userBoxHtml = $.parseHTML(userBoxHtml);
				userBoxMenu = $.parseHTML(userBoxMenu);
				
				$(".wide .btn-show-login").html(wideBtn);
			  	$(".narrow .btn-show-login").html(userBoxHtml);
			  	userBoxMenu = $(userBoxMenu).removeClass("dropdown-menu");
			  	userBoxMenu = $(userBoxMenu).removeClass("pull-right"); 
			  			  	
			  	$("#right-sidebar").html(userBoxMenu);
				$(".btn-logout").bind('click touchstart',function(){if (!actionFlag) {actionFlagUp();
						var lmsUrl = $.getMeta('property','lms');
						var logoutUrl = lmsUrl + "/local/ubauth/api/logout_popup.php";
						var target = this.id;
						
						var popWidth = 450;
						var popHeight = 450; 
						var left = (screen.width/2)-(popWidth/2);
						var top = (screen.height/2)-(popHeight/2);
			
						popupChildWindow = popupCenter(logoutUrl,'로그아웃', popWidth, popHeight, {toolbar:1, resizable:0, location:1, menubar:1, status:1}); 
			
						popupTimer = setInterval('popupPolling()', 1000);
					}
				});
				
						  	
			  break;
			  default:
			  	var portal_url = $.getMeta('property','portal_url');	
			  	var userBoxHtml = '<div href="' + portal_url + '" target="_blank">로그인</div>';
			  	
			  	userBoxHtml = $.parseHTML(userBoxHtml);

				$(".btn-show-login").html(userBoxHtml);  
			  break;
		  }
	  }
	});
}

function finishedLogout(){
	//$('#logout-modal').iziModal('close');
	parent.document.location.reload();
}

function requestLogout(){
	var token = $.getMeta('property','token');	
	
	var transData = {
		'_token' : token
	}
	
	$.ajax({
	  type:'POST',
	  url: '/auth/requestLogout',
	  data : transData,
	  dataType: "json",
	  success: function(data){
		  switch(data.code){ 
			  case 100:
			  	var lmsUrl = $.getMeta('property','lms');
			  	var remoteLogout =  lmsUrl + "/local/ubauth/api/logout_modal.php";
			  	window.location.href = remoteLogout;		  	
			  break;
			  default:
			  	
			  break;
		  }
	  }
	});	
}

function openLogoutModal(){
	var lmsUrl = $.getMeta('property','lms');
	var logoutUrl = "/auth/logout";
	var themeColor = $.getMeta('name','theme-color');
	
	
	
	$("#logout-modal").iziModal({
	    title: '로그아웃',
	    //subtitle: 'OCW 로그인 하기',
	    headerColor: themeColor,
	    padding: 18,
	    radius: 3,
	    zindex: 999,
	    appendTo: '.body', // or false,
	    borderBottom: 'false',
	    navigateArrows: 'closeToModal', // Boolean, 'closeToModal', 'closeScreenEdge'
	    restoreDefaultContent: false,
	    autoOpen: 0,
	    bodyOverflow: false,
	    fullscreen: false,
	    openFullscreen: false,
	    closeOnEscape: true,
	    closeButton: true,
	    overlay: true,
	    overlayClose: false,
		iframe: true,
		iframeHeight: 150,
		iframeURL: logoutUrl	    
	    
	});
	
	$('#logout-modal').iziModal('open');
	
}

function openAuthModal(){
	var lmsUrl = $.getMeta('property','lms');
	var lmsAuthUrl = $.getMeta('property','lms_auth');
	if(lmsAuthUrl == ''){
		lmsAuthUrl = lmsUrl; 
	}
	
	var authUrl = lmsAuthUrl + "/local/ubauth/index.php?rd=" + window.location.origin + "/auth/checkAuth";

	var themeColor = $.getMeta('name','theme-color');
	
	$("#auth-modal").iziModal({
	    title: '로그인',
	    //subtitle: 'OCW 로그인 하기',
	    headerColor: themeColor,
	    padding: 18,
	    radius: 3,
	    zindex: 999,
	    appendTo: '.body', // or false,
	    borderBottom: 'false',
	    navigateArrows: 'closeToModal', // Boolean, 'closeToModal', 'closeScreenEdge'
	    restoreDefaultContent: false,
	    autoOpen: 0,
	    bodyOverflow: false,
	    fullscreen: false,
	    openFullscreen: false,
	    closeOnEscape: true,
	    closeButton: true,
	    overlay: true,
	    overlayClose: false,
		iframe: true,
		iframeHeight: 140,
		iframeURL: authUrl	    
	    
	});
	
	$('#auth-modal').iziModal('open');

}
